import React, { useEffect } from 'react';
const Redirect = props => {
  useEffect(() => {
    const { toPath } = props.pageContext;
    const search = window.location.search;
    window.location.href = `${toPath}${search}`;
  }, [props.pageContext.toPath]);
  return <div>redirecting...</div>;
};

export default Redirect;
